<div class="card mb-3">
    <div class="card-header card-header-icon">
        <div class="card-icon">
            <i class="material-icons-outlined">sms</i>
        </div>
        <h4 class="card-title">
            SMS {{ title }}
            <div *ngIf="disabled" class="float-end warning-text text-warning">
                <i class="material-icons-outlined text-warning">info</i>
                SMS template editing will be available soon
            </div>
        </h4>
    </div>
    <div class="card-body px-3 pb-0">
        <div class="row">
            <div class="col-md-12">
                <div [ngClass]="{ 'd-none': isTinyMceLoaded }" class="preloader-block w-100 justify-content-center align-items-center">
                    <mat-spinner [diameter]="45"></mat-spinner>
                </div>
                <div [ngClass]="{ invisible: !isTinyMceLoaded }" class="w-100 justify-content-center align-items-center">
                    <div id="smsTemplateMessage"></div>
                    <span class="mat-error" [hidden]="!errorMessage">{{ errorMessage }}</span>
                    <span class="mat-text" *ngIf="!essentialCategories?.includes(typeId)"
                        >Standard opt-out instructions will be automatically added at the end of the message</span
                    >
                </div>
            </div>
        </div>
    </div>
</div>
