<div class="modal-header">
    <h4 class="modal-title" id="whatsNewLabel">New Phone Number Format!</h4>
</div>
<div class="modal-body">
    <p>
        We are adopting <strong>E.164</strong>, an internationally recognized standard phone number format, that helps ensure text messages
        and calls always reach the correct destination.
    </p>
    <p>
        If you continue, we will automatically {{ 'standardize' | translate }} all phone numbers of your contacts for you.
        <strong>Don’t worry, we will only replace those numbers that can be safely converted into the new format.</strong>
    </p>
    <mat-accordion>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title> How exactly do you process the numbers? </mat-panel-title>
            </mat-expansion-panel-header>

            <ul>
                <li>
                    Numbers in the national format will be converted to the international format. For example,
                    <strong>0412 345 678</strong> will be converted to <strong>+61 412 345 678</strong>.
                </li>
                <li>We take into consideration the country each contact comes from.</li>
                <li>If a contact’s country isn’t specified, we try to match their phone number against the country of this school.</li>
                <li>Numbers that fail to be converted will remain unchanged.</li>
                <li>Numbers that already meet the new standard will also remain unchanged.</li>
            </ul>
        </mat-expansion-panel>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title> What happens if I don’t do it? </mat-panel-title>
            </mat-expansion-panel-header>
            Nothing serious. You will need to fix invalid numbers on an individual basis later, but ONLY if you decide to change them or
            turn on the <strong>Receive Updates by SMS</strong> setting for the associated contact.
        </mat-expansion-panel>
    </mat-accordion>
</div>

<div class="modal-footer">
    <div class="mx-auto">
        <button type="button" mat-raised-button class="btn btn-cancel mx-1" (click)="onCancel()">Cancel</button>
        <button [promiseBtn]="promiseForBtn" type="button" mat-raised-button class="btn btn-success" (click)="sanitizeDataSubmit()">
            {{ 'Standardize' | translate }} My Numbers
        </button>
    </div>
</div>
