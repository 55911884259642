import { Injectable } from '@angular/core';

import { School } from '../../entities/school';
import { SchoolInfo } from './school.model';
import { SchoolStore } from './school.store';

@Injectable({ providedIn: 'root' })
export class SchoolService {
    constructor(private schoolStore: SchoolStore) {}

    set(school: School): void {
        this.schoolStore.updateSchool(school);
    }

    getSchool(): SchoolInfo {
        return this.schoolStore.getValue();
    }

    getStartingMonth(): number {
        return this.schoolStore.getValue().startingMonth;
    }

    getCountryId(): string {
        return this.schoolStore.getValue().countryId;
    }

    getSchoolName(): string {
        return this.schoolStore.getValue().name;
    }

    getSchoolExportType(): string {
        return this.schoolStore.getValue().exportType;
    }

    getSchoolSMSModuleState(): boolean {
        return this.schoolStore.getValue().isSmsEnabled;
    }
}
