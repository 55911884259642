import { HttpParams } from '@angular/common/http';
import { Sort } from '@angular/material/sort';
import { FieldType } from 'app/common/enums';
import { ISortQuery } from 'app/common/interfaces';
import { Keys } from 'app/common/keys';
import { FilterValue } from 'app/components/filter-constellation/interfaces/filter-value';
import * as _ from 'lodash';
import * as moment from 'moment';

export class CustomHttpParams extends HttpParams {
    set(param: string, value: string | number): CustomHttpParams {
        const httpParams = super.set(param, value);
        httpParams['generateIdsToSkip'] = this.generateIdsToSkip;
        httpParams['generateIdsToExclude'] = this.generateIdsToExclude;
        httpParams['generateIdsToInclude'] = this.generateIdsToInclude;
        httpParams['generateFields'] = this.generateFields;
        httpParams['generateIncludes'] = this.generateIncludes;
        httpParams['generateSort'] = this.generateSort;
        httpParams['generateOrder'] = this.generateOrder;
        httpParams['generateFilter'] = this.generateFilter;
        httpParams['generateGrouping'] = this.generateGrouping;
        httpParams['generateLimit'] = this.generateLimit;
        httpParams['set'] = this.set;
        httpParams['setIfNumber'] = this.setIfNumber;
        return httpParams as CustomHttpParams;
    }

    public generateIdsToSkip(ids: number[] = []): CustomHttpParams {
        return this.set('idsToSkip', _.join(ids, ','));
    }

    public generateIdsToExclude(ids: number[] = []): CustomHttpParams {
        return this.set('excludedIds', _.join(ids, ','));
    }

    public generateIdsToInclude(ids: number[] = []): CustomHttpParams {
        return this.set('includedIds', _.join(ids, ','));
    }

    public generateFields(fields: string[] = []): CustomHttpParams {
        return this.set('fields', fields.join(','));
    }

    public generateIncludes(includes: string[] = []): CustomHttpParams {
        return this.set('includes', includes.join(','));
    }

    public generateSort(sortState: Sort): CustomHttpParams {
        if (sortState?.direction) {
            return this.set('sort', `${(sortState.direction === 'asc' ? '' : '-') + sortState.active}`);
        } else {
            return this;
        }
    }

    public generateOrder(orders: ISortQuery[]): CustomHttpParams {
        const sortFields: string[] = [];
        orders.forEach(order => {
            sortFields.push(`${order.direction === 'ASC' ? '' : '-'}${order.field}`);
        });
        return this.set('sort', sortFields.join(','));
    }

    public generateFilters(filterValues: FilterValue[]): CustomHttpParams {
        let self: CustomHttpParams = this;
        if (!_.isArray(filterValues)) return self;
        for (const filter of filterValues) {
            self = self.generateFilter(filter);
        }
        return self;
    }

    public generateGrouping(fields: string[] = []): CustomHttpParams {
        return this.set('group', fields.join(','));
    }

    public generateLimit(limit?: number): CustomHttpParams {
        return limit ? this.set('limit', limit) : this;
    }

    public generateFilter(filterValue: FilterValue): CustomHttpParams {
        if (filterValue.id === Keys.enquiryDateRange || filterValue.id === Keys.birthDate) {
            const startDate = filterValue.value.startDate
                ? moment(filterValue.value.startDate, filterValue.value.format).utc().format()
                : '';
            const endDate = filterValue.value.endDate
                ? moment(filterValue.value.endDate, filterValue.value.format).endOf('day').utc().format()
                : '';
            return this.set(filterValue.id, `${startDate}_${endDate}`);
        } else {
            // ~ informs to backend that this value is string.
            return this.set(
                `${filterValue.id}${filterValue.type === FieldType.Dropdown ? '' : '~'}`,
                `${_.isArray(filterValue.value) ? _.join(filterValue.value, ',') : filterValue.value !== null ? filterValue.value : ''}`
            );
        }
    }

    public setIfNumber(key: string, value): CustomHttpParams {
        if (typeof value === 'number') {
            return this.set(key, value);
        }
        return this;
    }
}
