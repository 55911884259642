import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Angular2PromiseButtonModule } from 'angular2-promise-buttons';
import { Constants } from 'app/common/constants';
import { ModalAction } from 'app/common/enums';
import { firstValueFrom } from 'rxjs';
import Swal, { SweetAlertResult } from 'sweetalert2';

import { SanitizeMobileModalService } from './sanitize-mobile-modal.service';

@Component({
    selector: 'app-sanitize-mobile-modal',
    templateUrl: './sanitize-mobile-modal.component.html',
    providers: [SanitizeMobileModalService],
    imports: [CommonModule, MatExpansionModule, Angular2PromiseButtonModule, TranslateModule],
    standalone: true,
})
export class SanitizeMobileModalComponent implements OnInit {
    promiseForBtn: Promise<{ successCount: number; errorCount: number }>;
    countContacts = 0;

    constructor(
        private activeModal: NgbActiveModal,
        private sanitizeMobileModalService: SanitizeMobileModalService,
        private translate: TranslateService
    ) {}
    async ngOnInit() {
        this.countContacts = await this.sanitizeMobileModalService.countContacts();
    }

    async sanitizeDataSubmit() {
        this.promiseForBtn = this.sanitizeMobileModalService.sanitizeMobileNumbers();
        const result = await this.promiseForBtn;
        this.activeModal.close({ action: ModalAction.Select });
        await this.afterSubmit(result);
    }

    async onCancel() {
        this.activeModal.close({ action: ModalAction.Cancel });
    }

    public async afterSubmit(result: { successCount: number; errorCount: number }): Promise<SweetAlertResult> {
        const standardize = await firstValueFrom(this.translate.get('standardize'));
        return Swal.fire({
            title: 'Done!',
            icon: 'success',
            html: `<br>\
            Out of total <strong> ${result.successCount + result.errorCount}</strong> phone numbers: <br><br>\
            <strong>${result.successCount}</strong> were successfully  ${standardize}d; <br><br>\
            <strong>${result.errorCount}</strong> could not be ${standardize}d and are preserved in their original format.\
            You will be able to fix them later on an individual basis.<br><br>`,
            ...Constants.successOnly,
            buttonsStyling: false,
            allowOutsideClick: false,
        });
    }
}
