import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
// import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { enquiriesEBMChartMetaDataLegends } from 'app/common/chart-utils';
import { ChartType, KeyValueCategory } from 'app/common/enums';
import { Keys } from 'app/common/keys';
import { StorageAccessor } from 'app/common/storage/storage-accessor';
import { VersionInfo } from 'app/common/versionInfo';
import { StorageService } from 'app/services/storage.service';
import { ChartQuery, ChartService } from 'app/state/chart';
import { environment } from 'environments/environment';
import * as _ from 'lodash';

import { Constants } from '../../common/constants';
import { Utils } from '../../common/utils';
import { User } from '../../entities/user';
import { UserInfo } from '../../entities/userInfo';
import { HttpService } from '../../services/http.service';
import { FilterValue } from '../filter-constellation/interfaces/filter-value';

// import { SanitizeMobileModalComponent } from '../sanitize-mobile-modal/sanitize-mobile-modal.component';

declare let $: any;

@Component({
    selector: 'app-user-profile-cmp',
    templateUrl: 'user-profile.component.html',
    styleUrls: ['user-profile.component.scss'],
})
export class UserProfileComponent implements OnInit, OnDestroy {
    userProfileForm: UntypedFormGroup;
    userInfo: UserInfo = null;
    user: User = null;
    secondaryRole: string;
    loaded = false;
    isMobile = false;
    requiredTextFieldMinLength = Constants.requiredTextFieldMinLength;
    length50 = Constants.length50;
    length60 = Constants.length60;
    public brand = environment.brand;

    constructor(
        private httpService: HttpService,
        private fb: UntypedFormBuilder,
        private storageService: StorageService,
        private chartService: ChartService,
        private chartQuery: ChartQuery // private modalService: NgbModal
    ) {}

    async ngOnInit() {
        this.userInfo = Utils.getUserInfoFromToken();
        await this.getUserProfile();

        this.createUserProfileForm();
        this.loaded = true;

        if (window.screen.width < 470) {
            this.isMobile = true;
        }
    }

    private getUserProfile(): Promise<any> {
        return this.httpService
            .getAuth('users/get-current')
            .then((user: User) => {
                this.user = user;
                this.secondaryRole = user.roles?.filter(r => r !== user.role).shift();
                return this.checkUserVersion();
            })
            .catch(err => console.log(err));
    }

    private createUserProfileForm() {
        this.userProfileForm = this.fb.group({
            id: [this.user.id],
            firstName: [
                this.user.firstName,
                Validators.compose([
                    Validators.required,
                    Validators.minLength(this.requiredTextFieldMinLength),
                    Validators.maxLength(this.length50),
                ]),
            ],
            lastName: [
                this.user.lastName,
                Validators.compose([
                    Validators.required,
                    Validators.minLength(this.requiredTextFieldMinLength),
                    Validators.maxLength(this.length50),
                ]),
            ],
            email: [this.user.email],
            role: [this.user.role],
            title: [
                this.user.title,
                Validators.compose([
                    Validators.required,
                    Validators.minLength(Constants.requiredTextFieldMinLength),
                    Validators.maxLength(this.length60),
                ]),
            ],
            sendNotifications: [this.user.sendNotifications],
        });
    }

    onSubmit() {
        this.httpService
            .postAuth('users/save-profile', this.userProfileForm.value)
            .then(() => {
                $('#userProfileModal').modal('hide');
                this.userProfileForm.markAsPristine();
                this.user = this.userProfileForm.value;
                Utils.showSuccessNotification();
            })
            .catch(err => {
                console.log(err);
            });
    }

    userProfileCancel() {
        this.createUserProfileForm();
        $('#userProfileModal').modal('hide');
    }

    onCancel() {
        $('#whatsNewModal').modal('hide');
    }

    // showSanitizeDataModal() {
    //     this.userProfileCancel();
    //     this.modalService.open(SanitizeMobileModalComponent, Constants.ngbModalLg);
    // }

    async checkUserVersion() {
        const userVersion: string = this.user.last_shown_version;
        const currentVersion: string = Constants.version;
        if (this.isNewRelease(userVersion, currentVersion)) {
            $('#whatsNewModal').modal('show');
        }
        if (userVersion !== currentVersion) {
            // always update if different, also for hot fixes
            // TODO remove next three lines when merging back 0.11.2 to develp[]
            if (currentVersion === '0.11.2' || currentVersion === '0.11.3') {
                await this.storageService.resetFilters();
            }

            // const versionNumber = parseFloat(currentVersion);
            // if (
            //     this.isNewRelease(userVersion, currentVersion) &&
            //     this.userInfo.isSchoolAdmin() &&
            //     versionNumber >= 2.9 &&
            //     versionNumber < 3.0
            // ) {
            //     await this.showSanitizeDataModal();
            // }
            if (new VersionInfo('1.6.0').isHigherVersion(new VersionInfo(userVersion ?? currentVersion))) {
                this.updateEnquiryByMonthChartMetaData();
            }
            if (new VersionInfo('2.6.0').isHigherVersion(new VersionInfo(userVersion ?? currentVersion))) {
                await this.removeContactAlumniFilterOnStudentList();
            }
            if (new VersionInfo('2.6.1').isHigherVersion(new VersionInfo(userVersion ?? currentVersion))) {
                this.updateEnquiryYearlyComparisonChartIconData();
            }
            this.user.last_shown_version = currentVersion;
            this.httpService.postAuth('users/update-shown-version', { version: currentVersion }).catch(err => {
                console.log(err);
            });
        }
    }

    async removeContactAlumniFilterOnStudentList() {
        const storageAccessor: StorageAccessor = this.storageService.getStorageAccessor(KeyValueCategory.CATEGORY_FILTER);
        const studentsBfTableFilter: FilterValue[] = await storageAccessor?.get('studentsBfTable');
        if (studentsBfTableFilter?.length) {
            // remove filter value because enums(HasAlumni.Yes, HasAlumni.No, HasAlumni.Unknown) not equal to listItemId
            _.remove(studentsBfTableFilter, i => i.id === Keys.bfContactAlumniId);
            await storageAccessor.update('studentsBfTable', studentsBfTableFilter);
        }
    }

    updateEnquiryByMonthChartMetaData() {
        const id = `${Keys.enquiryByMonth}_${this.userInfo.id}`;
        const chartEntityModel = this.chartQuery.get(id);
        if (chartEntityModel?.chartMetaData?.legends) {
            const chartEntity = { ...chartEntityModel };
            const chartMetaLegends = [];
            _.forEach(chartEntityModel.chartMetaData.legends, legend => {
                const chartMetaData = _.find(enquiriesEBMChartMetaDataLegends, data => legend.id === data.id);
                const myLegend = legend.name !== chartMetaData.name ? { ...legend, name: chartMetaData.name } : legend;
                chartMetaLegends.push(myLegend);
            });
            chartEntity.chartMetaData = { legends: chartMetaLegends, shapes: chartEntityModel.chartMetaData.shapes };
            this.chartService.update(id, chartEntity);
        }
    }

    updateEnquiryYearlyComparisonChartIconData() {
        const id = `${Keys.enquiriesYearlyComparison}_${this.userInfo.id}`;
        const chartEntityModel = this.chartQuery.get(id);
        if (chartEntityModel?.chartTypeSelector) {
            const chartEntity = { ...chartEntityModel };
            if (chartEntityModel.chartTypeSelector.type === ChartType.HeatMap) {
                const chartTypeSelector = _.find(Constants.chartTypeSelectors, data => ChartType.HeatMap === data.type);
                chartEntity.chartTypeSelector = chartTypeSelector;
            }
            this.chartService.update(id, chartEntity);
        }
    }
    /**
     * Checks if the version number means a new release. we only look at the first 3 digits for this. e.g if the current
     * version is 0.9.1, 0.10.0 would mean a new minor release, but 0.9.2 only a hotfix
     */
    private isNewRelease(userversionNumber: string, currentVersionNumber: string): boolean {
        const userVersion = userversionNumber ? Utils.TryParseNumbersFromArray(userversionNumber.split('.')) : [0, 0, 0];
        const currentVersion = currentVersionNumber ? Utils.TryParseNumbersFromArray(currentVersionNumber.split('.')) : [0, 0, 0];
        let isNewRelease = false;
        if (this.isVersionValid(userVersion) && this.isVersionValid(currentVersion)) {
            if (
                currentVersion[0] > userVersion[0] || // new major version
                (currentVersion[0] === userVersion[0] && currentVersion[1] > userVersion[1]) // new minor version
            ) {
                isNewRelease = true;
            }
        }
        return isNewRelease;
    }

    private isVersionValid(versionArray: Array<number | null>) {
        // check if data is valid, should be in format w.x.y.z or w.x.y where w,z,y,z are all valid numbers (not null)
        const isVersionValid = (versionArray.length === 3 || versionArray.length === 4) && !_(versionArray).some(el => el == null);
        // if the version is not valid, show it console, this should never happen!
        if (!isVersionValid) {
            console.error('ET version number has invalid format!');
        }

        return isVersionValid;
    }

    public ngOnDestroy() {
        Utils.disposeModal('#userProfileModal');
        Utils.disposeModal('#whatsNewModal');
    }
}
