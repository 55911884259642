import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, Input, NgZone, forwardRef } from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    FormsModule,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ReactiveFormsModule,
    ValidationErrors,
    Validator,
} from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { RouterModule } from '@angular/router';
import { Constants } from 'app/common/constants';
import { EmailTemplateTypeId } from 'app/common/enums';
import { InsertField } from 'app/common/interfaces';
import { T } from 'app/common/t';
import { TinyMceConfig, initMenuButton } from 'app/common/tinymce-helper';
import { SharedModule } from 'app/shared.module';
import tinymce from 'tinymce/tinymce.min';

@Component({
    selector: 'app-sms-message',
    standalone: true,
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        MatInputModule,
        MatProgressSpinnerModule,
        MatIconModule,
        SharedModule,
    ],
    templateUrl: './sms-message.component.html',
    styleUrls: ['./sms-message.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SmsMessageComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => SmsMessageComponent),
            multi: true,
        },
    ],
})
export class SmsMessageComponent implements ControlValueAccessor, AfterViewInit, Validator {
    @Input() insertFields: InsertField[] = [];
    @Input() typeId: EmailTemplateTypeId;
    @Input() title: 'Message' | 'Template' = 'Message';
    content: string = '';
    disabled = false;
    isTinyMceLoaded = false;
    editorId = 'smsTemplateMessage';
    smsFieldMaxLength = Constants.requiredSmsMessageMaxLength;
    smsMessageErrorText = `This field is required and max length is ${Constants.requiredSmsMessageMaxLength} characters`;
    essentialCategories = Constants.essentialCategories;
    errorMessage = '';

    constructor(private zone: NgZone) {}

    onChange: (value: string) => void = () => {};
    onTouched: () => void = () => {};

    writeValue(value: string): void {
        this.content = value || '';
        this.setEditorContent();
    }

    private setEditorContent(): void {
        if (this.isTinyMceLoaded) {
            const editor = tinymce.get(this.editorId);
            if (editor) {
                editor.setContent(this.content);
            }
        }
    }

    validate(control: AbstractControl): ValidationErrors | null {
        const value = control.value?.trim() || '';

        if (!value) {
            this.errorMessage = this.smsMessageErrorText;
            return { required: true };
        }

        if (value.length > this.smsFieldMaxLength) {
            this.errorMessage = this.smsMessageErrorText;
            return { maxLength: { requiredLength: this.smsFieldMaxLength, actualLength: value.length } };
        }
        this.errorMessage = '';
        return null;
    }

    registerOnChange(fn: () => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
        if (this.isTinyMceLoaded) {
            const editor = tinymce.get(this.editorId);
            if (editor) {
                editor.mode.set(isDisabled ? 'readonly' : 'design');
            }
        }
    }

    updateContent(value: string): void {
        this.content = value;
        this.onChange(value);
    }

    ngAfterViewInit(): void {
        this.insertFields = this.insertFields.filter(field => field.text !== T.emailSignatureText);
        this.initTinyMCE();
    }

    private initTinyMCE() {
        const initialValue = this.content; // Store initial content

        const config: Partial<TinyMceConfig> = {
            max_chars: this.smsFieldMaxLength,
            convert_urls: false,
            relative_urls: false,
            force_br_newlines: true,
            forced_root_block: 'text',
            height: '160',
            statusbar: true,
            branding: true,
            inline: false,
            fixed_toolbar_container: '',
            menubar: false,
            plugins: [''],
            toolbar: 'insertFields | resetContent',
            toolbar_mode: 'wrap',
            selector: `#${this.editorId}`,
            setup: editor => {
                // Initialize insert fields menu
                const items = this.insertFields.map(field => ({
                    ...field,
                    type: 'menuitem',
                    onAction: () => {
                        editor.insertContent(field.value);
                    },
                }));

                // Add Reset Button
                editor.ui.registry.addButton('resetContent', {
                    icon: 'reload', // TinyMCE built-in refresh icon
                    tooltip: 'Reset to Default Template',
                    onAction: () => {
                        editor.setContent(initialValue); // Reset content
                        this.zone.run(() => this.updateContent(initialValue)); // Update form control
                    },
                });

                editor.on('init', () => {
                    this.isTinyMceLoaded = true;
                    this.setEditorContent(); // Set content on init
                    if (this.disabled) {
                        editor.mode.set('readonly');
                    }
                });

                initMenuButton(editor, items);

                editor.on('change keyup keydown input', () => {
                    this.zone.run(() => {
                        this.updateContent(editor.getContent({ format: 'text' }));
                    });
                });
            },
        };

        tinymce.init(config);
    }
}
