import { Constants } from 'app/common/constants';
import { MenuItem } from 'app/common/interfaces/menu-item';
import { UserInfo } from 'app/entities/userInfo';

export function getOrgLayoutRoutes(userInfo: UserInfo, translations: any, id: number): MenuItem[] {
    const appRoutes: MenuItem[] = [
        {
            path: `${Constants.baseOrgUrl}/${id}/summary`,
            title: 'Summary',
            type: 'link',
            icontype: 'dashboard',
            hidden: !(userInfo.isSysAdmin() || userInfo.isOrgUser()),
        },
        {
            path: `${Constants.baseOrgUrl}`,
            title: `${translations.et.enquiries}`,
            type: 'sub',
            icontype: 'account_box',
            collapse: `${translations.et.enquiries}`,
            hidden: !(userInfo.isSysAdmin() || userInfo.isSchoolAdmin() || userInfo.isOrgUser()),
            children: [
                { path: `/${id}/families`, title: `${translations.et.enquiries}`, ab: 'people_outline' },
                { path: `/${id}/schools`, title: 'Schools', ab: 'account_balance' },
            ],
        },
        {
            path: Constants.baseOrgUrl,
            title: 'Analytics',
            type: 'sub',
            icontype: 'insert_chart',
            collapse: 'analytics',
            hidden: !(userInfo.isSysAdmin() || userInfo.isSchoolAdmin() || userInfo.isOrgUser()),
            children: [{ path: `/${id}/analytics/research`, title: 'Research', ab: 'analytics' }],
        },
        {
            path: `${Constants.baseOrgUrl}`,
            title: 'Admin',
            type: 'sub',
            icontype: 'settings_applications',
            collapse: 'admin',
            hidden: !(userInfo.isSysAdmin() || userInfo.isSchoolAdmin() || userInfo.isOrgUser()),
            children: [
                { path: `/${id}`, title: `Edit ${translations.et.organisation}`, ab: 'mode_edit' },
                { path: `/${id}/users`, title: `${translations.et.organisation} Users`, ab: 'people_outline' },
            ],
        },
        {
            path: `${Constants.baseOrgUrl}`,
            title: 'System Admin',
            type: 'sub',
            icontype: 'settings_applications',
            collapse: 'system-admin',
            hidden: !userInfo.isSysAdmin(),
            children: [
                { path: '/', title: `${translations.et.organisation}s`, ab: 'corporate_fare' },
                { path: `/${id}/assigned-schools`, title: 'Assigned Schools', ab: 'list' },
            ],
        },
    ];
    return appRoutes;
}
