<div
    class="modal fade"
    id="userProfileModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="userProfileLabel"
    aria-hidden="true"
    data-backdrop="static"
    data-keyboard="false"
>
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title text-start" id="userProfileLabel">User Profile</h4>
                <button type="button" class="btn-close" tabindex="-1" aria-label="Close" (click)="userProfileCancel()"></button>
            </div>
            <form [formGroup]="userProfileForm" novalidate (ngSubmit)="onSubmit()" *ngIf="loaded" class="m-0">
                <div class="modal-body">
                    <div>
                        <mat-form-field>
                            <input matInput placeholder="First Name" type="text" formControlName="firstName" required />
                            <mat-error
                                [hidden]="
                                    userProfileForm.controls.firstName.valid ||
                                    (!userProfileForm.controls.firstName.value && userProfileForm.controls.firstName.pristine)
                                "
                            >
                                First Name should contain at least {{ requiredTextFieldMinLength }} and at most {{ length50 }} characters.
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field>
                            <input matInput placeholder="Last Name" type="text" formControlName="lastName" required />
                            <mat-error
                                [hidden]="
                                    userProfileForm.controls.lastName.valid ||
                                    (!userProfileForm.controls.lastName.value && userProfileForm.controls.lastName.pristine)
                                "
                            >
                                Last Name should contain at least {{ requiredTextFieldMinLength }} and at most {{ length50 }} characters.
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field>
                            <input matInput placeholder="Job Title" type="text" formControlName="title" required />
                            <mat-error
                                [hidden]="
                                    userProfileForm.controls.title.valid ||
                                    (!userProfileForm.controls.title.value && userProfileForm.controls.title.pristine)
                                "
                            >
                                Job Title should contain at least {{ requiredTextFieldMinLength }} and at most {{ length60 }} characters.
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="row">
                        <label class="col-md-5 label-on-left">Email:</label>
                        <div class="col-md-7">
                            {{ user.email }}
                        </div>
                    </div>
                    <div class="row">
                        <label class="col-md-5 label-on-left">Access Level:</label>
                        <div class="col-md-7">{{ user.role }}</div>
                        <ng-container *ngIf="secondaryRole">
                            <label class="col-md-5 label-on-left">Secondary Access Level:</label>
                            <div class="col-md-7">{{ secondaryRole }}</div>
                        </ng-container>
                    </div>
                    <div class="user-notifications" *ngIf="!userInfo.isSysAdmin() && !userInfo.isOrgAdmin()">
                        <mat-checkbox formControlName="sendNotifications"> Receive email notifications on form submissions </mat-checkbox>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" mat-raised-button class="btn btn-cancel mx-1" (click)="userProfileCancel()">Cancel</button>
                    <button
                        type="submit"
                        mat-raised-button
                        class="btn btn-success"
                        [disabled]="!userProfileForm.valid || userProfileForm.pristine"
                    >
                        Save
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
<div class="modal fade" id="whatsNewModal" tabindex="-1" role="dialog" aria-labelledby="whatsNewLabel">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title text-start" id="whatsNewLabel">New Stuff!</h4>
                <button type="button" class="btn-close" tabindex="-1" aria-label="Close" (click)="onCancel()"></button>
            </div>
            <div class="modal-body">
                <p>
                    We’ve been working hard to incorporate your feedback, add new features, fix problems and include more all around cool
                    stuff.
                    <br />
                    <br />
                    You can learn more about what’s included in this release
                    <a href="{{ brand.whatsNewUrl }}" target="_blank">here</a>
                </p>
            </div>
            <div class="modal-footer">
                <button type="button" mat-raised-button class="btn btn-success" (click)="onCancel()">Ok</button>
            </div>
        </div>
    </div>
</div>
