import * as _ from 'lodash';
import 'tinymce/plugins/code/plugin';
import 'tinymce/plugins/image/plugin';
import 'tinymce/plugins/link/plugin';
import 'tinymce/plugins/lists/plugin';
import 'tinymce/themes/silver';
import tinymce from 'tinymce/tinymce.min';

import { Constants } from './constants';

// toolbars
const toolbarGroups = [
    'blocks',
    'bold italic underline',
    'forecolor',
    'fontfamily',
    'fontsize',
    'alignleft aligncenter alignright alignjustify',
    'bullist numlist',
    'link',
    'image',
    'removeformat',
    'insertFields',
];

const adminToolbarGroups = ['code'];

const fieldEditorToolbarGroups = _.without(
    toolbarGroups,
    'forecolor',
    'fontfamily',
    'fontsize',
    'alignleft aligncenter alignright alignjustify'
);

export const basicToolbar = toolbarGroups.join(' | ');
export const adminToolbar = toolbarGroups.concat(adminToolbarGroups).join(' | ');
export const fieldEditorToolbar = fieldEditorToolbarGroups.join(' | ');

// configurations
export interface TinyMceConfig {
    convert_urls: boolean;
    relative_urls: boolean;
    skin_url: string;
    selector: string;
    force_br_newlines: boolean;
    forced_root_block: string | boolean;
    height: string;
    statusbar: boolean;
    branding: boolean;
    inline: boolean;
    fixed_toolbar_container: string;
    menubar: boolean;
    plugins: string[];
    fontsize_formats: string;
    default_link_target: string;
    toolbar: string;
    setup: (editor) => void;
    max_chars: number;
    toolbar_mode: string;
}

const defaultConfig: Partial<TinyMceConfig> = {
    max_chars: Constants.fieldDescriptionMaxLength,
    convert_urls: false,
    relative_urls: false,
    force_br_newlines: true,
    forced_root_block: 'div', // see https://github.com/tinymce/tinymce/discussions/7342
    height: '330',
    statusbar: true,
    branding: true,
    inline: false,
    fixed_toolbar_container: '',
    menubar: false,
    plugins: ['link', 'image', 'code', 'lists'],
    fontsize_formats: '8pt 10pt 12pt 14pt 18pt 24pt 36pt',
    default_link_target: '_blank',
    toolbar: basicToolbar,
    toolbar_mode: 'wrap',
};

export function initTinyMCE(config: Partial<TinyMceConfig>) {
    tinymce.init({
        ...defaultConfig,
        ...config,
        setup(editor) {
            editor.addCommand('mceInsertLink', (ui, value) => {
                const formatter = editor.formatter;
                const dom = editor.dom;
                const selection = editor.selection;

                if (typeof value == 'string') {
                    value = { href: value };
                }

                const anchor = dom.getParent(selection.getNode(), 'a');

                // Remove existing links if there could be child links or that the href isn't specified
                if (!anchor || !value.href) {
                    formatter.remove('link');
                }

                // Apply new link to selection
                if (value.href) {
                    formatter.apply('link', value, anchor);
                }
            });
            if (config.setup) {
                config.setup(editor);
            }
            if (!config.max_chars) {
                return;
            }
            editor.on('init', () => {
                const counter = document.createElement('div');
                counter.style.textAlign = 'center';
                counter.setAttribute('class', 'char_count');

                document.getElementById(editor.id).parentElement.appendChild(counter);
                updateCharCounter(this, editor.getContent().length);
            });
            editor.on('change keydown input', () => {
                updateCharCounter(this, editor.getContent().length);
            });
        },
    });

    // for link options, without this, link will be not editable
    document.addEventListener('focusin', (e: any) => {
        if (e.target.closest('.mce-window')) {
            e.stopImmediatePropagation();
        }
    });
}

function updateCharCounter(editor, contentLength) {
    const counterElement = document.getElementById(editor.id)?.parentElement.getElementsByClassName('char_count');
    if (counterElement.length === 0) {
        return;
    }
    const max_chars = Constants.fieldDescriptionMaxLength;
    if (max_chars <= contentLength) {
        counterElement[0].className = 'char_count not-valid';
        counterElement[0].innerHTML = `Max text length exceeded ${contentLength}/${max_chars}`;
    } else {
        counterElement[0].className = 'char_count valid';
        counterElement[0].innerHTML = `${contentLength}/${max_chars}`;
    }
}

export function initMenuButton(editor, items) {
    editor.ui.registry.addMenuButton('insertFields', {
        text: '<Insert Fields>',
        fetch: callback => {
            callback(items);
        },
    });
}
