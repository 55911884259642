import { Injectable } from '@angular/core';
import { HttpService } from 'app/services/http.service';

@Injectable()
export class SanitizeMobileModalService {
    constructor(private httpService: HttpService) {}

    sanitizeMobileNumbers(): Promise<{ successCount: number; errorCount: number }> {
        return this.httpService.postAuth('contacts/sanitize-mobile', {});
    }

    countContacts(): Promise<number> {
        return this.httpService.getAuth('contacts/count-mobile');
    }
}
