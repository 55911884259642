import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, TitleStrategy } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'app/common/interfaces/menu-item';
import { School } from 'app/entities/school';
import { AppStateService } from 'app/services/app-state.service';
import { HttpService } from 'app/services/http.service';
import { SchoolQuery } from 'app/state/school';
import { TransferStateService } from 'app/state/transfer/transfer-state.service';

import { Constants } from '../../common/constants';
import { Utils } from '../../common/utils';
import { UserQuery } from '../../state/user/user.query';
import { BaseDashboardLayout } from '../base-dashboard-layout';
import { getAdminLayoutRoutes } from './admin-layout.config';

@Component({
    selector: 'app-admin-layout',
    templateUrl: './admin-layout.component.html',
})
export class AdminLayoutComponent extends BaseDashboardLayout implements OnInit, OnDestroy {
    school$ = this.schoolQuery.school$;

    constructor(
        location: Location,
        router: Router,
        translateService: TranslateService,
        appStateService: AppStateService,
        userQuery: UserQuery,
        titleStrategy: TitleStrategy,
        private schoolQuery: SchoolQuery,
        private httpService: HttpService,
        private transferStateService: TransferStateService
    ) {
        super(location, router, translateService, appStateService, userQuery, titleStrategy);
    }

    async ngOnInit() {
        await super.ngOnInit();
        this.appStateService.updateStateSchool();
        if (this.userInfo) {
            this.transferStateService.subscribeToTransfer(this.userInfo.id);
        }
    }

    protected async getMenuItems(): Promise<MenuItem[]> {
        let isEnabledAppModule = false;
        let isEnabledCommsModule = false;
        this.userInfo = Utils.getUserInfoFromToken();
        if (this.userInfo.isSchoolAdminOrLower) {
            const school = await this.httpService.getAuth<School>('schools/get/' + this.userInfo.schoolId);
            if (Utils.isSchoolModuleEnabled(school.modules, Constants.schoolModules.appModule.name)) {
                isEnabledAppModule = true;
            }
            if (Utils.isSchoolModuleEnabled(school.modules, Constants.schoolModules.commsModule.name)) {
                isEnabledCommsModule = true;
            }
        }
        return getAdminLayoutRoutes(this.userInfo, { isEnabledAppModule, isEnabledCommsModule }, this.translations);
    }

    ngOnDestroy(): void {
        this.transferStateService.unsubscribeFromTransfer();
        super.ngOnDestroy();
    }
}
